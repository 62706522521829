import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import {
  DialogOverlay as ReachDialogOverlay,
  DialogContent as ReachDialogContent,
} from "@reach/dialog";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { ThemedPageTitle } from "../components/pageTitle";
import ThemedBox from "../components/themedBox";
import Hero from "../components/hero";
import RichText from "../components/richText";
import { sectionHeading, pageTitle } from "../tokens/typography";
import ArtworkSvg from "../components/artworkSvg";
import { transparentize } from "polished";
import { getTrackColor } from "../tokens/colours";
import { Button } from "../components/button";
import { HideVisually } from "../tokens/a11y";
import Speaker from "../components/speaker";
import { formatDate, formatTime } from "../utils/dateTime";

function TrackTemplate({ data }) {
  const {
    name,
    description,
    eponymDescription,
    events = [],
    page = [],
    slug,
    alternativeName,
    registrationInformation = null,
    registrationUrl = null,
    moreInfoUrl = null,
  } = data.contentfulTrack;
  const trackNameForCss = alternativeName.toLowerCase();
  const breadcrumbs = [
    { name: "Home", href: "/" },
    { name: page[0].title, href: `/${page[0].slug}/` },
    { name: name },
  ];

  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  return (
    <Layout
      hero={
        <Hero breadcrumbs={breadcrumbs} hasRelativeContainer>
          <Artwork trackName={trackNameForCss} />
          <ThemedPageTitle trackName={trackNameForCss}>
            {name} - &ldquo;{alternativeName}&rdquo;
          </ThemedPageTitle>
        </Hero>
      }
    >
      <SEO title={name} />
      <MaxWidthBox>
        <RichText>{renderRichText(description)}</RichText>
        {registrationInformation && (
          <RegistrationInfo>
            {renderRichText(registrationInformation)}
          </RegistrationInfo>
        )}
        {moreInfoUrl && (
          <Link as="a" href={moreInfoUrl}>
            More information
          </Link>
        )}
        {registrationUrl && (
          <Link as="a" href={registrationUrl}>
            Book online
          </Link>
        )}
        {eponymDescription && (
          <div>
            <WhyTrackNameButton onClick={open}>
              Why &ldquo;{alternativeName}&rdquo;?
            </WhyTrackNameButton>
            <DialogOverlay isOpen={showDialog} onDismiss={close}>
              <DialogContent aria-label={`Why "${alternativeName}"?`}>
                <ModalSection>
                  <ModalHeader>
                    <ModalCloseButton onClick={close}>
                      <span aria-hidden>&times;</span>
                      <HideVisually>Close</HideVisually>
                    </ModalCloseButton>
                    <ModalTitle>
                      Why &ldquo;{alternativeName}&rdquo;?
                    </ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    {renderRichText(eponymDescription)}
                    <ModalArtwork trackName={trackNameForCss} />
                  </ModalBody>
                </ModalSection>
              </DialogContent>
            </DialogOverlay>
          </div>
        )}
      </MaxWidthBox>
      {trackNameForCss === "ada"
        ? events && (
            <>
              <OverallEventDate>
                {formatDate(events[0].startDatetime)}
              </OverallEventDate>
              {events.map((event) => {
                const {
                  id,
                  title,
                  description,
                  startDatetime,
                  endDatetime,
                  collaborators = [],
                } = event;
                return (
                  <Event key={id}>
                    <EventTitle>{title}</EventTitle>
                    <FlexContainer>
                      <Col1>
                        <span>
                          {formatTime(startDatetime)} -{" "}
                          {formatTime(endDatetime)}
                        </span>
                      </Col1>
                      <Col2>
                        <MaxWidthBox>{renderRichText(description)}</MaxWidthBox>
                        {collaborators.length > 0 && (
                          <>
                            <SpeakersHeading>
                              {collaborators.length > 1
                                ? "Meet our speakers"
                                : "Meet our speaker"}
                            </SpeakersHeading>
                            <SpeakersList>
                              {collaborators.map((collaborator) => {
                                const {
                                  id,
                                  name,
                                  slug,
                                  page = null,
                                  headshot = null,
                                  company = null,
                                } = collaborator;
                                const pageSlug = page && page[0].slug;
                                return (
                                  <Speaker
                                    key={id}
                                    pageSlug={pageSlug}
                                    slug={slug}
                                    headshot={headshot}
                                    name={name}
                                    company={company}
                                  />
                                );
                              })}
                            </SpeakersList>
                          </>
                        )}
                      </Col2>
                    </FlexContainer>
                  </Event>
                );
              })}
            </>
          )
        : events && (
            <EventsList>
              {events.map((event) => {
                const {
                  id,
                  title,
                  slug: eventSlug,
                  shortDescription,
                  startDatetime,
                  endDatetime,
                } = event;
                return (
                  <ThemedBox
                    key={id}
                    trackNameForCss={trackNameForCss}
                    alternativeName={alternativeName}
                    descriptiveTitle={title}
                    startDatetime={startDatetime}
                    endDatetime={endDatetime}
                    intro={renderRichText(shortDescription)}
                    moreInfoLink={`/${page[0].slug}/${slug}/${eventSlug}/`}
                  />
                );
              })}
            </EventsList>
          )}
    </Layout>
  );
}

export default TrackTemplate;

const Artwork = styled(ArtworkSvg)`
  position: absolute;
  right: 0;
  top: 0;
  max-width: 80%;
  max-height: 90%;
  z-index: -1;
  fill: none !important;
  stroke: ${({ theme }) => theme.palette.white};
  stroke-width: 2;
`;

const WhyTrackNameButton = styled(Button)`
  margin-top: 2rem;
`;

const ModalCloseButton = styled.button`
  margin: initial;
  padding: initial;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-transform: inherit;
  background-color: transparent;
  color: inherit;

  padding: 0.5rem;
  font-size: 2rem;
  line-height: 1;
`;

const ModalArtwork = styled(ArtworkSvg)`
  position: absolute;
  right: 0;
  top: 0;
  max-width: 80%;
  max-height: 90%;
  z-index: -1;
  fill: none !important;
  stroke: ${({ trackName }) => transparentize(0.8, getTrackColor(trackName))};
  stroke-width: 2;
`;

const EventsList = styled.div`
  margin-top: 3rem;
`;

const OverallEventDate = styled.h2`
  margin-top: 4rem;
  margin-bottom: -2rem;
`;

const Event = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
`;

const EventTitle = styled.h3`
  ${sectionHeading};

  margin-bottom: 1rem;
`;

const RegistrationInfo = styled.div`
  margin-top: 1rem;
  padding-top: 1rem;

  & > :first-child {
    margin-top: 0;
  }
`;

const Link = styled(Button)`
  white-space: nowrap;
  margin-top: 1rem;
  margin-right: 1rem;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-right: -1rem;
  margin-left: -1rem;
`;

const Col1 = styled.div`
  position: sticky;
  top: 1rem;
  z-index: 1;
  margin-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  box-shadow: 0 0 0.5rem 0.5rem ${({ theme }) => theme.palette.oxfordshireBlue};
  background-color: ${({ theme }) => theme.palette.oxfordshireBlue};
`;

const Col2 = styled.div`
  padding-right: 1rem;
  padding-left: 1rem;
`;

const MaxWidthBox = styled.div`
  max-width: 60ch;
`;

const SpeakersHeading = styled.h3`
  font-size: 1.2em;
`;

const SpeakersList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  padding-left: 0;
  list-style: none;
`;

const DialogOverlay = styled(ReachDialogOverlay)`
  z-index: 1;

  background-color: hsla(0, 0%, 0%, 0.33);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const DialogContent = styled(ReachDialogContent)`
  width: 90%;
  max-width: 40em;
  margin: 10vh auto;
  outline: none;
`;

const ModalSection = styled.section`
  position: relative;
  z-index: 1;
  padding: 1rem;
  background-color: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.oxfordshireBlue};

  @media (min-width: 32em) {
    padding: 2rem;
  }
`;

const ModalHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  background-color: ${({ theme }) => theme.palette.white};
`;

const ModalTitle = styled.h2`
  ${pageTitle};

  margin-top: 0;
`;
const ModalBody = styled.div`
  position: relative;
`;

export const pageQuery = graphql`
  query TrackBySlug($slug: String!) {
    contentfulTrack(slug: { eq: $slug }) {
      name
      slug
      alternativeName
      page {
        slug
        title
      }
      description {
        raw
      }
      eponymDescription {
        raw
      }
      registrationInformation {
        raw
      }
      registrationUrl
      moreInfoUrl
      events {
        id
        slug
        title
        startDatetime
        endDatetime
        shortDescription {
          raw
        }
        description {
          raw
        }
        collaborators {
          ... on ContentfulSpeaker {
            id
            name
            slug
            headshot {
              fluid(maxWidth: 200) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            company
            page {
              slug
            }
          }
        }
      }
    }
  }
`;
