export function getDateDisplay(startDatetime, endDatetime) {
  if (
    new Date(startDatetime).toDateString() ===
    new Date(endDatetime).toDateString()
  )
    return "sameDay";
  else return "multiDay";
}

export function formatDateTime(
  dateString,
  timezone = "Europe/London",
  locale = "en-gb"
) {
  return new Date(dateString).toLocaleDateString(locale, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: timezone,
  });
}

export function formatDate(
  dateString,
  timezone = "Europe/London",
  locale = "en-gb"
) {
  return new Date(dateString).toLocaleDateString(locale, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: timezone,
  });
}

export function formatTime(
  timeString,
  timezone = "Europe/London",
  locale = "en-gb"
) {
  return new Date(timeString).toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: timezone,
  });
}
